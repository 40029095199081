import classNames from 'classnames';
import { ReactNode } from 'react';

import ThemedColor, { ColorTheme } from '@tb-core/components/themed/color';
import { Props } from '@tb-core/types';

import styles from './styles.module.scss';

export type ThemableChildren = <T extends { className?: string }>(
    props: T
) => ReactNode;

interface ThemedProps extends Props {
    className?: string;
}

export interface ColorThemableProps extends ThemedProps {
    border?: boolean;
    children: ThemableChildren;
    inverse?: boolean;
    theme?: ColorTheme;
}

const ColorThemable = ({
    border = true,
    children,
    className,
    inverse = false,
    theme,
    ...props
}: ColorThemableProps) => (
    <ThemedColor theme={theme}>
        {({ bgColor, color }) =>
            children({
                ...props,
                className: classNames(
                    {
                        [styles['no-border']]: !border,
                        [styles.inverse]: inverse,
                        [bgColor]: !inverse,
                        [color]: inverse
                    },
                    className
                )
            })
        }
    </ThemedColor>
);

export default ColorThemable;
