import classNames from 'classnames';
import { ReactNode } from 'react';

import Svg from '@tb-core/components/simple/svg';

import styles from './styles.module.scss';

interface InputWithErrorProps {
    children: ReactNode;
    className?: string;
    error?: boolean;
    message?: string;
    name?: string;
}

const InputWithError = ({
    children,
    className,
    error,
    message,
    name
}: InputWithErrorProps) => (
    <div className={classNames(styles['has-error'], className)}>
        <div className={error ? styles['border-red'] : ''}>{children}</div>
        {error && (
            <>
                <span className={styles['error-icon']}>
                    <Svg
                        className={
                            name === 'zip-code' ? styles['zipcode-error'] : ''
                        }
                        size="16px"
                        svgId="icon-circle-exclamation"
                    />
                </span>
                <div className={styles.error}>
                    <span>{message}</span>
                </div>
            </>
        )}
    </div>
);

export default InputWithError;
