import { datadogLogs } from '@datadog/browser-logs';
import {
    AuthorizationNotifier,
    AuthorizationRequest,
    BasicQueryStringUtils,
    GRANT_TYPE_AUTHORIZATION_CODE,
    LocationLike,
    RedirectRequestHandler
} from '@openid/appauth';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { webOrigin } from '@tb-core/helpers/next-env';
import { GetAndSetDeviceId } from '@tb-core/helpers/storage';
import {
    fetchAuthConfig,
    oAuthConfigSettings
} from '@tb-core/providers/auth/fetch-auth-config';
import { makeTokenRequest } from '@tb-core/providers/auth/make-token-request';

export interface GuestToCustomerRequestProps {
    email?: string;
    first_name?: string;
    last_name?: string;
}

interface UseOauthProps {
    callBack?: () => void;
    isPasswordless?: boolean;
    redirectUrl?: string;
    stateRedirect?: string;
}

export class SimpleQueryStringUtils extends BasicQueryStringUtils {
    constructor() {
        super();
    }

    parse = (input: LocationLike) => {
        return this.parseQueryString(input.search);
    };
}

export const useOauth = ({
    callBack,
    isPasswordless,
    redirectUrl, // /login or /register
    stateRedirect
}: UseOauthProps) => {
    const notifier = new AuthorizationNotifier();
    const [authorizationHandler, setAuthorizationHandler] = useState<
        RedirectRequestHandler
    >();

    useEffect(() => {
        setAuthorizationHandler(
            new RedirectRequestHandler(undefined, new SimpleQueryStringUtils())
        );
    }, []);

    useEffect(() => {
        if (authorizationHandler && !isPasswordless) {
            notifier?.setAuthorizationListener(
                async (request, response, error) => {
                    if (response) {
                        // Make access token request
                        await makeTokenRequest(
                            {
                                code: response.code,
                                extras: {
                                    code_verifier:
                                        request.internal?.code_verifier || ''
                                },
                                grant_type: GRANT_TYPE_AUTHORIZATION_CODE
                            },
                            redirectUrl
                        );

                        if (callBack) {
                            callBack();
                        }
                    } else if (error) {
                        datadogLogs.logger.log(
                            'Unable to fetch access token',
                            { error },
                            'error'
                        );
                    }
                }
            );

            authorizationHandler.setAuthorizationNotifier(notifier);
            authorizationHandler.completeAuthorizationRequestIfPossible();
        }
    }, [authorizationHandler]);

    // Sends the user to yum hosted auth pages
    const makeAuthorizationRequest = async (extras = { action: 'login' }) => {
        try {
            const config = await fetchAuthConfig();
            const deviceId = GetAndSetDeviceId();

            if (config && authorizationHandler) {
                const authSettings = {
                    ...oAuthConfigSettings,
                    extras: {
                        ...extras,
                        device_id: deviceId
                    },
                    redirect_uri: webOrigin + redirectUrl,
                    state: JSON.stringify({
                        action:
                            extras?.action === 'register'
                                ? 'register'
                                : 'login',
                        id: uuid(),
                        redirect: stateRedirect // Used to redirect after going back to login page
                    })
                };

                authorizationHandler.performAuthorizationRequest(
                    config,
                    new AuthorizationRequest(authSettings)
                );
            }
        } catch (error) {
            datadogLogs.logger.log(
                'Unable to perform authorization request',
                { error },
                'error'
            );
        }
    };

    // send the user to the register page
    const makeRegistrationAuthorizationRequest = () =>
        makeAuthorizationRequest({
            action: 'register'
        });

    // Send the user to the sign in page
    const makeSignInAuthorizationRequest = () => makeAuthorizationRequest();

    return {
        authorizationHandlerReady: !!authorizationHandler,
        makeRegistrationAuthorizationRequest,
        makeSignInAuthorizationRequest
    };
};
