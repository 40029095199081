import classNames from 'classnames';
import React from 'react';

import SafeAnchorWithGaEvent, {
    SafeAnchorWithGaEventProps
} from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import ThemedColor from '@tb-core/components/themed/color';

const SecondaryLink = ({
    children,
    className,
    ...props
}: SafeAnchorWithGaEventProps) => (
    <ThemedColor theme={'secondary'}>
        {({ color }) => (
            <SafeAnchorWithGaEvent
                {...props}
                className={classNames(color, className)}
            >
                {children}
            </SafeAnchorWithGaEvent>
        )}
    </ThemedColor>
);

export default SecondaryLink;
