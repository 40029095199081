import BreadCrumbs from '@tb-core/components/styled/bread-crumbs';
import usePageContext from '@tb-core/hooks/use-page-context';

import styles from './styles.module.scss';

const AuthHeader = () => {
    const { headerCurrentPage, headerLabel, headerUrl } =
        usePageContext().content.pageData.topicMeta.generic || {};

    return (
        <header className={styles['auth-header']}>
            <BreadCrumbs
                links={[{ label: headerLabel, href: headerUrl }]}
                title={headerCurrentPage}
            />
        </header>
    );
};

export default AuthHeader;
