import SecondaryLink from '@tb-core/components/simple/links/secondary-link';

import styles from './styles.module.scss';

export interface BreadcrumbLinkProps {
    href: string;
    label: string;
}

interface BreadCrumbProps {
    links: BreadcrumbLinkProps[];
    title: string;
}

const Link = ({ link }: { link: BreadcrumbLinkProps }) => (
    <SecondaryLink href={link.href}>{link.label}</SecondaryLink>
);

const BreadCrumbs = ({ links, title }: BreadCrumbProps) => (
    <div className={styles['crumbs']}>
        {links?.map((link, index) =>
            index > 0 ? (
                <span key={link.href}>
                    <span> &lt; </span>
                    <Link link={link} />
                </span>
            ) : (
                <span key={link.href}>
                    <Link link={link} />
                </span>
            )
        )}
        {title && <span className={styles['crumb-text']}>{` < ${title}`}</span>}
    </div>
);

export default BreadCrumbs;
