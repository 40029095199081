import classNames from 'classnames';

import StandardModal from '@tb-core/components/composites/standard-modal';
import BrandedButton from '@tb-core/components/styled/buttons/branded-button';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';

import styles from './styles.module.scss';

const ErrorModal = () => {
    const { modals, updateModals } = useLayoutActivityContext();
    const {
        buttonText,
        callback,
        className,
        description,
        showHeader,
        image,
        title
    } = modals['error-modal'] || {};

    const ctaClickHandler = () => {
        if (callback) {
            callback();
        }
        closeModal();
    };

    const closeModal = () =>
        updateModals({
            ['error-modal']: undefined
        });

    const renderBody = () => (
        <>
            {image}
            {title && (
                <h3
                    className={styles['error-modal-title']}
                    id="error-modal-title"
                    tabIndex={0}
                >
                    {title}
                </h3>
            )}
            {description && (
                <p
                    className={styles['error-modal-description']}
                    id="error-modal-title"
                    tabIndex={0}
                >
                    {description}
                </p>
            )}
        </>
    );

    return (
        <div className={styles['error-modal-wrapper']}>
            <StandardModal
                ariaDescribedby="error-modal-description"
                ariaLabelledby="error-modal-title"
                body={renderBody()}
                className={classNames(styles.modal, className)}
                footer={
                    <div>
                        <BrandedButton
                            aria-label={'Error'}
                            className={styles['error-modal-button']}
                            onClick={ctaClickHandler}
                        >
                            {buttonText || 'Close'}
                        </BrandedButton>
                    </div>
                }
                showHeader={showHeader}
                contentStyle={styles['content-style']}
                id="error-modal"
                onClose={closeModal}
                role="dialog"
            />
        </div>
    );
};

export default ErrorModal;
